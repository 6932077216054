
const apiRootUri = process.env.REACT_APP_API_ROOT_URI;

async function postAsync(resourceUri, payload, accessToken) {
    const headers = {
        "Content-Type": "application/json",
        Authorization: accessToken !== undefined
            ? `Bearer ${accessToken}`
            : undefined
    };

    const params = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload)
    };

    var response = await fetch(`${apiRootUri}/${resourceUri}`, params);

    return response;
}

export { postAsync }