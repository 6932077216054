import { postAsync } from "./ApiService";

async function stayInTheLoop(email) {
    var response = await postAsync("lurkers",
        {
            emailAddress: email
        }
    );

    var message = await response.text();

    return {
        success: response.ok,
        message: message
    };
}

export { stayInTheLoop }