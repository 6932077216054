import React from 'react'
import LogoPlaceholder from './LogoPlaceholder'
import { Outlet } from 'react-router-dom'

const LandingNavbar = () => {
    return (
        <>
            <div className='h-16 w-full absolute top-0 left-0 right-0 md:px-32 px-16 py-4 flex items-center justify-between md:px-32 px-16'>
                <LogoPlaceholder></LogoPlaceholder>
                <div className='flex gap-x-8'>
                    <a href='#about' className='text-gray-600'>Learn more</a>
                    <a href='https://www.buymeacoffee.com/ninaoffline' target='_blank' rel='noopener noreferrer' className='text-gray-600'>Support</a>
                </div>
            </div>
            <Outlet></Outlet>
        </>
    )
}

export default LandingNavbar