import React from 'react'

const About = () => {
    return (
        <div className='h-screen pt-16 text-gray-800 flex flex-col gap-y-4 text-justify'>
            <p className='text-xl'>About</p>
            <p>
                Peer is a <span className='font-semibold'>collaborative platform</span> bringing together students, researchers, industry professionals, and enthusiasts from all disciplines to make their ideas a reality.
            </p>

            <p>
                Whether you're working on something exciting and need expert help, a mentor, or extra hands on deck, our platform helps you spread the word and reach exceptional people who might otherwise fly under the radar. You can also join existing projects and initiatives, as we'll deliver the opportunities best suited for you straight to your homepage.
            </p>

            <p>
                Peer is an ongoing project. 
                We welcome your suggestions and inquiries at <a className='text-indigo-600' href="mailto:hello@joinpeer.co">hello@joinpeer.co</a>. 
                If you'd like to support our work, consider <a href='https://www.buymeacoffee.com/ninaoffline' target='_blank' rel="noopener noreferrer" className='underline font-semibold'>making a small donation</a>.
            </p>
        </div>
    )
}

export default About