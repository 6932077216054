import React from 'react'

const LogoPlaceholder = () => {
  const plea = "Placeholder logo - designer urgently needed! If interested, hmu at nina@joinpeer.co.";

  return (
    <img
      src='favicon.ico'
      className='w-12 h-12 -ml-2'
      alt={plea}
      title={plea}
    >
    </img>
  )
}

export default LogoPlaceholder