import React, { useState } from 'react'
import { stayInTheLoop } from '../../services/NewsletterService';

const LandingCta = () => {
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(undefined);
    const [message, setMessage] = useState(undefined);

    const successMessage = "Thank you for choosing to stay up to date with the platform development!";
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const handleEmailChange = e => {
        setEmail(e.target.value);
        setMessage(undefined);
    };

    const emailValid = email => email.toLowerCase()
        .match(emailRegex);

    const handleStayInTheLoop = async (e) => {
        e.preventDefault();

        if (!emailValid(email)) {
            setEmailSent(false);
            setMessage("Please enter a valid email address")

            return;
        }

        let response = await stayInTheLoop(email);

        if (response.success) {
            setEmailSent(true);
            setMessage(successMessage);
        } else {
            setEmailSent(false);
            setMessage(response.message);
        }
    };

    const cta = <>
        <form onSubmit={e => e.preventDefault()}>
            <div className='flex gap-x-2 flex-wrap gap-y-2'>
                <input
                    type='email'
                    required
                    placeholder='Your email here'
                    value={email}
                    onChange={handleEmailChange}
                    className='rounded-full h-10 px-4 border border-indigo-200 text-gray-800'>
                </input>

                <button
                    type='submit'
                    onClick={handleStayInTheLoop}
                    className='rounded-full w-fit py-2 px-4 font-semibold flex shrink-0 cursor-pointer bg-indigo-600 text-gray-50 hover:bg-indigo-700'>
                    Stay in the loop
                </button>
            </div>
        </form>
    </>

    const ctaSuccess = <>
        <p className='text-gray-600'>{message}</p>
    </>

    const ctaFailure = <>
        <p className='text-red-500'>{message}</p>
    </>

    return (
        <div className='flex flex-col gap-y-2'>
            {cta}
            {
                emailSent && ctaSuccess
            }
            {
                emailSent === false && ctaFailure
            }
        </div>
    )

}

export default LandingCta