import React from 'react'
import { TypeAnimation } from 'react-type-animation'
import LandingCta from '../comps/landing/LandingCta';
import About from '../comps/landing/About';

const Landing = () => {

    const changeInterval = 2000;

    return (
        <div className='md:px-32 px-16 pb-64 flex flex-col'>
            <div className='md:pt-44 pt-32 flex flex-col gap-y-8 h-screen'>
                <div className='flex flex-col gap-y-4'>
                    <span className='lg:text-5xl md:text-3xl text-2xl font-semibold text-left text-gray-800'>
                        <span>Find amazing&nbsp;</span>
                        <span className='inline-block md:inline font-black text-indigo-500 w-64'>
                            <TypeAnimation
                                preRenderFirstString={true}
                                sequence={[
                                    'people',
                                    changeInterval * 2,
                                    'backend developers',
                                    changeInterval,
                                    'designers',
                                    changeInterval,
                                    'product people',
                                    changeInterval,
                                    'artists',
                                    changeInterval,
                                    'math majors',
                                    changeInterval,
                                    'mentors',
                                    changeInterval,
                                    'volunteers',
                                    changeInterval,
                                ]}
                                wrapper="span"
                                speed={20}
                                repeat={Infinity}
                            />
                        </span>
                        <br />
                        <span>and bring your ideas to life</span>
                    </span>

                    <p className='text-gray-600'>Whether you're a student, researcher, industry professional - or all of the above - <span className='font-semibold'>peer</span> helps you find your people.</p>
                </div>

                <LandingCta></LandingCta>
            </div>

            <div className='lg:w-1/2' id="about">
                <About></About>
            </div>
        </div>
    )
}

export default Landing